<template>
  <div class="main-container">
    <div class="main-heading">
      <h1>{{ "Treasure Hunt" }}</h1>
      <Button
        :onClick="() => submit()"
        type="info"
        text="Submit"
        :loading="isLoading"
      />
    </div>
    <div class="first-section">
      <div class="col">
        <div class="form-field">
          <h4>{{ $t(`EVENT_VALUES.START_DATE`) }}</h4>
          <DatePicker
            v-model.number="event.start_date"
            mode="dateTime"
            :min-date="Date.now() - 7 * 24 * 60 * 60 * 1000"
            :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input class="input" id="start_date" :value="inputValue" v-on="inputEvents" />
            </template>
          </DatePicker>
        </div>
        <div class="form-field">
          <h4>{{ $t(`EVENT_VALUES.END_DATE`) }}</h4>
          <DatePicker
            v-model.number="event.end_date"
            mode="dateTime"
            :min-date="Date.now()"
            :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input class="input" id="end_date" :value="inputValue" v-on="inputEvents" />
            </template>
          </DatePicker>
        </div>
        <div class="form-field">
          <h4>Spin Price (Coconut Milks per Spin)</h4>
          <input
            type="number"
            class="input"
            v-model.number="event.spin_price"
          />
        </div>
        <div class="form-field">
          <h4>Unlocked Arena</h4>
          <input
            type="number"
            class="input"
            v-model.number="event.unlocked_arena"
          />
        </div>
        <div class="form-field">
          <h4>Min Cannon Ball for 5 Spins</h4>
          <input
            type="number"
            class="input"
            v-model.number="event.min_cannon_ball_per_five_spins"
          />
        </div>
        <div class="form-field">
          <h4>Spin Extra Cannon Ball Probability (%)</h4>
          <input
            type="number"
            class="input"
            v-model.number="event.extra_cannon_ball_probability"
          />
        </div>
        <div class="form-field">
          <h4> Coconut Milk Coefficient On Event Pass Purchase</h4>
          <input
            type="number"
            class="input"
            v-model.number="event.event_pass_purchase_coefficient"
          />
        </div>
        <div>
          <h4>Event Quest Reward Types</h4>
            <div v-for="(questType, index) in event.quest_reward_types"
              v-bind:key="index"
              class="form-field">
              <h5>{{ questType.text }}</h5>
              <input
                type="number"
                class="input"
                v-model="questType.amount"
              />
              <br>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-field">
          <h4>Language</h4>
          <select class="input" v-model="selectedLanguage">
            <option
              v-for="language in languages"
              v-bind:key="language.code"
              :value="language.code"
            >
              {{ language.name }}
            </option>
          </select>
        </div>
        <div class="header-with-button">
          <h4>Info Fields</h4>
          <div class="flex">
            <Button
              :loading="false"
              text="Get Defaults"
              size="sm"
              :onClick="() => getDefaultInfoFields()"
              type="info"
            />
            <Button
              :loading="false"
              text="Add"
              size="sm"
              :onClick="() => addInfoField()"
              type="success"
            />
            <Button
              :loading="false"
              text="Remove"
              size="sm"
              :onClick="() => removeInfoField(event.info_fields.length - 1)"
              type="error"
            />
          </div>
        </div>
        <div
          v-for="(field, index) in event.info_fields"
          v-bind:key="index"
          class="info-field-container"
        >
          <div class="form-container">
            <p>Icon</p>
            <select class="input" v-model.number="field.icon">
              <option
                v-for="type in constants.INFO_FIELD_ICONS"
                v-bind:key="type.value"
                :value="type.value"
              >
                {{ $t(type.text) }}
              </option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ "Field Title" }}</p>
            <input class="input" v-model="field.name[selectedLanguage]" />
          </div>
          <div class="form-container">
            <p>{{ "Text" }}</p>
            <textarea class="input" v-model="field.text[selectedLanguage]" />
          </div>
        </div>
      </div>
    </div>
    <h3>Road Rewards</h3>
    <div class="table">
      <div class="row row-header">
        <div class="cell cell-header" :style="{ width: 100 / 6 + '%' }">
          Type
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 6 + '%' }">
          Rarity
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 6 + '%' }">
          Chest
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 6 + '%' }">
          Amount
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 6 + '%' }">
          Avatar Index
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 6 + '%' }">
          Emoji Index
        </div>
      </div>
      <div
        v-for="(reward, index) in event.road_rewards"
        v-bind:key="index"
        class="row"
      >
        <div class="cell" :style="{ width: 100 / 6 + '%' }">
          <select
            v-model.number="event.road_rewards[index].type"
            @change="
              initializeRewardType(
                index,
                event.road_rewards[index].type,
                event.road_rewards
              )
            "
          >
            <option
              v-for="option in constants.REWARD_TYPES"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("REWARD_VALUES." + option.text) }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 6 + '%' }">
          <select
            v-model.number="event.road_rewards[index].rarity"
            :disabled="![3, 4].includes(event.road_rewards[index].type)"
          >
            <option
              v-for="option in constants.CARD_RARITY"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("REWARD_VALUES." + option.text) }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 6 + '%' }">
          <select
            v-model="event.road_rewards[index].id"
            :disabled="event.road_rewards[index].type !== 2"
          >
            <option
              v-for="option in chests"
              :value="option.id"
              v-bind:key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 6 + '%' }">
          <input
            :disabled="[6, 14].includes(event.road_rewards[index].type)"
            type="number"
            v-model.number="event.road_rewards[index].amount"
          />
        </div>
        <div class="cell" :style="{ width: 100 / 6 + '%' }">
          <input
            :disabled="event.road_rewards[index].type !== 14"
            type="number"
            v-model.number="event.road_rewards[index].avatar_index"
          />
        </div>
        <div class="cell" :style="{ width: 100 / 6 + '%' }">
          <input
            :disabled="event.road_rewards[index].type !== 6"
            type="number"
            v-model.number="event.road_rewards[index].emoji_index"
          />
        </div>
      </div>
    </div>
    <h3>Cannon Ball Rewards</h3>
    <div class="table">
      <div class="row row-header">
        <div class="cell cell-header" :style="{ width: 100 / 6 + '%' }">
          Type
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 6 + '%' }">
          Rarity
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 6 + '%' }">
          Chest
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 6 + '%' }">
          Amount
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 6 + '%' }">
          Avatar Index
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 6 + '%' }">
          Emoji Index
        </div>
      </div>
      <div
        v-for="(reward, index) in event.cb_rewards"
        v-bind:key="index"
        class="row"
      >
        <div class="cell" :style="{ width: 100 / 6 + '%' }">
          <select
            v-model.number="event.cb_rewards[index].type"
            @change="
              initializeRewardType(
                index,
                event.cb_rewards[index].type,
                event.cb_rewards
              )
            "
          >
            <option
              v-for="option in constants.REWARD_TYPES"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("REWARD_VALUES." + option.text) }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 6 + '%' }">
          <select
            v-model.number="event.cb_rewards[index].rarity"
            :disabled="![3, 4].includes(event.cb_rewards[index].type)"
          >
            <option
              v-for="option in constants.CARD_RARITY"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("REWARD_VALUES." + option.text) }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 6 + '%' }">
          <select
            v-model="event.cb_rewards[index].id"
            :disabled="event.cb_rewards[index].type !== 2"
          >
            <option
              v-for="option in chests"
              :value="option.id"
              v-bind:key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 6 + '%' }">
          <input
            :disabled="[6, 14].includes(event.cb_rewards[index].type)"
            type="number"
            v-model.number="event.cb_rewards[index].amount"
          />
        </div>
        <div class="cell" :style="{ width: 100 / 6 + '%' }">
          <input
            :disabled="event.cb_rewards[index].type !== 14"
            type="number"
            v-model.number="event.cb_rewards[index].avatar_index"
          />
        </div>
        <div class="cell" :style="{ width: 100 / 6 + '%' }">
          <input
            :disabled="event.cb_rewards[index].type !== 6"
            type="number"
            v-model.number="event.cb_rewards[index].emoji_index"
          />
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
      "
    >
      <h3>Spin Rewards</h3>
      <Button
        size="sm"
        text="Add"
        :loading="false"
        :disabled="false"
        type="success"
        :onClick="() => addSpinReward()"
      />
    </div>
    <div class="table">
      <div class="row row-header">
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Type
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Rarity
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Chest
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Amount
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Avatar Index
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Emoji Index
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Probability Weight
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Actions
        </div>
      </div>
      <div
        v-for="(reward, index) in event.spin_rewards"
        v-bind:key="index"
        class="row"
      >
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <select
            v-model.number="event.spin_rewards[index].reward.type"
            :disabled="index === 0"
            @change="
              initializeRewardTypeSpin(
                index,
                event.spin_rewards[index].reward.type
              )
            "
          >
            <option
              v-for="option in [...constants.REWARD_TYPES, { text: 'CANNON_BALL', value: 16 }]"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("REWARD_VALUES." + option.text) }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <select
            v-model.number="event.spin_rewards[index].reward.rarity"
            :disabled="![3, 4].includes(event.spin_rewards[index].reward.type)"
          >
            <option
              v-for="option in constants.CARD_RARITY"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("REWARD_VALUES." + option.text) }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <select
            v-model="event.spin_rewards[index].reward.id"
            :disabled="event.spin_rewards[index].reward.type !== 2"
          >
            <option
              v-for="option in chests"
              :value="option.id"
              v-bind:key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <input
            :disabled="[6, 14].includes(event.spin_rewards[index].reward.type)"
            type="number"
            v-model.number="event.spin_rewards[index].reward.amount"
          />
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <input
            :disabled="event.spin_rewards[index].reward.type !== 14"
            type="number"
            v-model.number="event.spin_rewards[index].reward.avatar_index"
          />
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <input
            :disabled="event.spin_rewards[index].reward.type !== 6"
            type="number"
            v-model.number="event.spin_rewards[index].reward.emoji_index"
          />
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <input v-model.number="event.spin_rewards[index].probability" />
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <Button
            size="sm"
            text="Remove"
            :loading="false"
            :disabled="index === 0"
            type="error"
            :onClick="() => removeSpinReward(index)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue";
import dispatchMap from "@/constants/dispatchMap";
import { useStore } from "vuex";
import eventConstants from "../../constants/eventConstants";
import unitConstants from "../../constants/unitConstants";
import Button from "../../components/common/Button.vue";
import 'v-calendar/dist/style.css';
import languageConstants from "@/constants/languagesTemp";

export default {
  name: "TreasureHunt",
  components: {
    Button,
  },
  setup() {
    const store = useStore();

    const usedPages = ["events"];

    const isLoading = ref(false);
    const event = ref({});
    const events = computed(() => store.getters["events/getEvents"]);
    const chests = computed(() => store.getters["chests/getChests"]);
    const languages = languageConstants.languages;
    const selectedLanguage = ref("en");
    onBeforeMount(() =>
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => store.dispatch(dispatchStr));
      })
    );

    onMounted(() => {
      if (events.value.length > 0) {
        event.value = JSON.parse(
          JSON.stringify(events.value.find((x) => x.type === 4) || {})
        );
        store.dispatch("loader/loadingStatus", false);
      }
      /*if (!event.value.info_fields) {
        event.value.info_fields = [{ icon: 0, name: "", text: "" }];
        event.value.localizables = { name: {}, short_description: {} };
      }*/
    });
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(
      () => store.getters["events/getEvents"],
      (value) => {
        event.value = JSON.parse(
          JSON.stringify([...value].find((x) => x.type === 4) || {})
        );
        if (event.value.id) store.dispatch("loader/loadingStatus", false);
        //store.dispatch("loader/loadingStatus", false);
        /*if (!event.value.info_fields) {
          event.value.info_fields = [{ icon: 0, name: "", text: "" }];
          event.value.localizables = { name: {}, short_description: {} };
        }*/
      },
      { immediate: true }
    );

    const initializeRewardType = (index, type, target) => {
      const reward = {
        type,
        amount: 0,
      };
      if ([3, 4].includes(type)) reward.rarity = 0;
      if (type === 2) reward.id = "";

      if ([6, 14].includes(type)) delete reward.amount;

      target[index] = reward;
    };

    const initializeRewardTypeSpin = (index, type) => {
      const reward = {
        type,
        amount: 0,
      };
      if ([3, 4].includes(type)) reward.rarity = 0;
      if (type === 2) reward.id = "";

      if ([6, 14].includes(type)) delete reward.amount;

      event.value.spin_rewards[index].reward = reward;
    };

    const addSpinReward = () => {
      event.value.spin_rewards.push({
        reward: { type: 0, amount: 0 },
        probability: 0,
      });
    };

    const removeSpinReward = (index) => {
      event.value.spin_rewards.splice(index, 1);
    };

    const submit = () => {
      isLoading.value = true;
      store
        .dispatch("events/updateEvent", {
          id: "treasure-hunt",
          updateBody: event.value,
        })
        .then(() => {
          //
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    return {
      event,
      chests,
      constants: {
        REWARD_TYPES: eventConstants.EVENT_REWARD_TYPES,
        CARD_RARITY: unitConstants.RARITY,
        INFO_FIELD_ICONS: eventConstants.INFO_FIELD_ICONS
      },
      initializeRewardType,
      initializeRewardTypeSpin,
      addSpinReward,
      removeSpinReward,
      isLoading,
      languages,
      submit,
      selectedLanguage
    };
  },
};
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  width: 300px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 10px;
}

.input-multi {
  width: 300px;
  height: 35px;
}

.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}

a {
  color: blue;
  text-decoration: underline;
}

a:hover {
  cursor: pointer;
}

.row {
  flex-grow: 1;
  max-width: 100%;
  display: inline-flex;
  max-height: 70px;
  overflow: hidden;
}

.cell.cell-header {
  font-size: 0.95rem;
  font-weight: 600;
  border-bottom: 1px solid gray;
}

.cell {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  width: 100%;
  flex-grow: 1;
  max-width: 85vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
  margin-bottom: 50px;
}

.table select {
  width: 90%;
  height: 35px;
}

.table input {
  width: 90%;
  height: 35px;
}

.info-field-container {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  margin-bottom: 20px;
}

.header-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flex {
  display: flex;
}

.margin {
  margin: 10px;
}
</style>